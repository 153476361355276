import styled from 'styled-components';
import { Section }  from '../Layout/styles';
import * as T from '../Typography';

export const Info = styled(Section)``;

export const Headline = styled.div``;

export const Text = styled(T.SmallBodyCopy)`
  margin-top: var(--x2-space);
  color: var(--grey);

  @media (min-width: ${props => props.theme.tabletBreak}) {
    margin-top: var(--x4-space);
    padding-left: 30%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: var(--x1-space);
      left: 0;
      width: 86px;
      height: 1px;
      background-color: var(--grey);
    }
  }
`;
