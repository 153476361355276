import * as React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { Grid, Cell } from 'styled-css-grid';
import * as T from '../Typography';
import * as S from './styles';
import ReactMarkdown from 'react-markdown';
import { useMobileBreakpoint } from '../../utils/breakpoints';

export interface IProps {
  // TODO readd
  breakpoints: any;
  currentBreakpoint: string;
  headline: string;
  text: string;
}

const Info = (props: IProps) => {
  const { breakpoints, currentBreakpoint } = props;
  const isMobile = useMobileBreakpoint(breakpoints, currentBreakpoint);

  return (
    <S.Info>
      <Grid columns={24}>
        <Cell width={22} left={2}>
          <Grid columns={12}>
            <Cell width={isMobile ? 11 : 6} left={isMobile ? 1 : 4}>
              <S.Headline as={isMobile ? T.H4 : T.H2}>
                <h2>
                  {props.headline}
                </h2>
              </S.Headline>
              <S.Text>
                <ReactMarkdown source={props.text} />
              </S.Text>
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </S.Info>
  );
};

export default withBreakpoints(Info);
