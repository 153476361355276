import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import MediaRight from '../components/MediaRight';
import Column from '../components/Column';
import Info from '../components/Info';
import Carousel from '../components/Carousel';
import BoxImage from '../components/BoxImage';
import Background from '../components/Background';
import Head from '../components/Head';

const LabsPage = ({ data }) => {
  const labs = data.labs.frontmatter;
  return (
    <Layout>
      <Background />
      <Head title={labs.title} description={labs.description} />
      <Header {...labs.header} />
      <MediaRight overlapBottom={true} {...labs.mediaRight} />
      <Column hasLine={true} {...labs.column} />
      <Info {...labs.info} />
      <Carousel {...labs.carousel} />
      <BoxImage {...labs.boxImage2} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query SiteLabsPageQuery {
    labs: markdownRemark(frontmatter: { templateKey: { eq: "z1-labs" } }) {
      frontmatter {
        title
        description
        header {
          headline
          straplines
          address
        }
        mediaRight {
          image {
            childImageSharp {
              fluid(quality: 85, maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          label
          headline
          strapline
          text
        }
        column {
          backgroundColor
          color
          label
          headline
          text1
          text2
        }
        info {
          headline
          text
        }
        carousel {
          backgroundColor
          label
          headline
          slides {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            caption
          }
        }
        boxImage2 {
          boxes2 {
            image {
              childImageSharp {
                fluid(quality: 85, maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            headline
            button {
              label
              url
            }
          }
        }
      }
    }
  }
`;

export default LabsPage;
